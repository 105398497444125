<template>
  <div class="warp">
    <div class="header">
      <van-nav-bar
          title="我的积分"
          left-arrow fixed
          @click-left="goBack"
      />
    </div>

    <div class="bodyWarp">
      <div class="headerImg">
        <img src="@/assets/all_Icon/drawable-xxhdpi/my_point_bg.png">
        <div class="conent">
          <p>{{ totalPointValue }}</p>
          <p class="desc">总计</p>
        </div>
        <div class="headerTitle">积分明细</div>
      </div>
      <div class="list" v-if="list&&list.length>0">
        <van-row class="listItem" v-for="(item,index) in list" :key="index">
          <van-col span="18">
            <p class="liTitle">{{ titleType[item.type] }}</p>
            <p class="liTime">{{ item.intime }}</p>
          </van-col>
          <van-col span="6">
            <div class="right" v-if="item.status === 1">+{{ item.pointValue }}</div>
            <div class="right red" v-if="item.status === 2">-{{ item.pointValue }}</div>
          </van-col>
        </van-row>
      </div>
      <div v-else>
        <van-empty description="暂无数据"></van-empty>
      </div>
    </div>

  </div>
</template>


<script>

import {getPointList} from "@/api/user.js";

export default {
  data() {
    return {
      list: [],
      totalPointValue: 0,
      titleType: {
        1: '课程赠送',
        2: '课程消费'
      }
    };
  },
  name: 'integral',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    getPointList() {
      getPointList().then(res => {
        if (res.data.code === 0) {
          this.totalPointValue = res.data.data.totalPointValue;
          this.list = res.data.data.pointChildList;
        }
      })
    }
  },
  created() {
    this.getPointList();
  }
};
</script>

<style lang="less" scoped>

.header {
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
}

/deep/ .van-nav-bar__title {
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}

/deep/ .header .van-icon-arrow-left {
  color: #fff;
}

/deep/ .van-nav-bar__content {
  background: linear-gradient(90deg, #4388FF 0%, #8A6BFE 100%);
}

/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}

.bodyWarp {
  margin-top: 42px;

  .headerImg {
    height: 260px;
    width: 100%;
    position: relative;

    img {
      width: 100%;
      height: 100%;
    }

    .conent {
      width: 100%;
      position: absolute;
      top: 34px;
      left: 0;

      p {
        width: 100%;
        text-align: center;
        height: 28px;
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 30px;
        padding: 9px 0;
      }

      .desc {
        height: 19px;
        font-size: 14px;
        line-height: 19px;
      }
    }

    .headerTitle {
      position: absolute;
      bottom: 14px;
      left: 14px;
      height: 23px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #141414;
      line-height: 23px;
    }
  }

  .list {
    height: calc(100vh - 306px);
    overflow-y: auto;
    .listItem {
      height: 40px;
      margin: 0 14px;
      padding: 10px 0;
      border-bottom: 1px solid #DDDDDD;

      .liTitle {
        height: 22px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3A3A3A;
        line-height: 22px;
      }

      .liTime {
        margin-top: 5px;
        height: 17px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 17px;
      }

      .right {
        height: 40px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5F7DFF;
        line-height: 40px;
        text-align: right;
      }

      .red {
        color: #D65B70;
      }
    }
  }
}
</style>


